<template>
  <div class='page-box'>
    <div class="summary">
      <div class="item">
        <div class="name">金额: {{summary.allotWaitMoney}}</div>
      </div>
      <div class="item">
        <div class="name">单价: {{summary.price}}</div>
      </div>
      <div class="item">
        <div class="name">分配股数: {{summary.allotAs}}</div>
      </div>
    </div>
    <base-screen class="custom-screen">
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <a-row>
          <a-form-model-item label="手机号" prop="loginName">
            <a-input :maxLength="11" v-model="queryRef.loginName" placeholder="手机号"></a-input>
          </a-form-model-item>
          <a-form-model-item label="注册时间" prop="dateRange">
            <a-range-picker v-model='queryRef.dateRange' format="YYYY-MM-DD" value-format="YYYY-MM-DD" />
          </a-form-model-item>
          <a-form-model-item label="用户类型" prop="userType">
            <a-select v-model="queryRef.userType" placeholder='请选择' style="width: 200px">
              <a-select-option v-for='item in userTypes' :key='item.value' :value="item.value">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item class="screen-btns">
            <a-button type="primary" @click="handleSearch">查询</a-button>
            <a-button @click="handleReset">重置</a-button>
          </a-form-model-item>
        </a-row>
      </a-form-model>
    </base-screen>
    <div class='btn-box'>
      <a-button type='primary' v-if='state == 0' @click='selectUser'>选择用户</a-button>
    </div>
    <base-table :columns="columns" :dataSource="dataRef" :pagination="pageRtv" :rowKey="record => record.detailId" :loading="loadingRef" @change="handleTableChange">
      <template #userType="record">
        <span>{{userTypes.find(x=>x.value === record).name}}</span>
      </template>
      <template #operation="_,record">
        <div class='table-operations '>
<!--          <a-button type='link' v-if='state == 0' @click='edit(record)'>编辑</a-button>-->
          <a-button type='link' v-if='state == 0' @click='deleteDetail(record)'>删除</a-button>
        </div>
      </template>
    </base-table>
    <a-modal v-model='visibleEdit' :width="800" title='编辑购买分配明细' @ok='handleSubmitEdit' @cancel='handelCancelEdit'>
      <a-form-model ref='formEditRef' :model="form" :wrapper-col="{span:16}" :label-col="{span:8}">
        <a-form-model-item label='手机号' prop='loginName'>
          <a-input v-model="form.loginName" placeholder="请输入" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item label='金额' prop='saleBeforeAs'>
          <a-input v-model="form.saleBeforeAs" placeholder='请输入' :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label='指导销售股数' prop='saleAs' :rules="[
          { required: true, message: '请输入' },
          { pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/, message: '必须大于0' }
        ]">
          <a-input-number :precision="2" :min="0" :max='form.saleBeforeAs' v-model="form.saleAs" placeholder='请输入' style="width: 150px"
                          @change="changeSaleAsEdit()"></a-input-number>
        </a-form-model-item>
        <a-form-model-item label='剩余股数' prop='saleAfterAs'>
          <a-input-number :precision="2" v-model="form.saleAfterAs" placeholder='请输入' style="width: 150px" :disabled="true"></a-input-number>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal v-model='visibleAdd' width="70%" title='选择购买分配用户' @ok='handleSubmit' @cancel='handelCancel'
             okText="添加所选" cancelText="取消">
      <div class="summary">
        <div class="item">
          <div class="name">金额: {{summarySelect.allotWaitMoney}}</div>
        </div>
        <div class="item">
          <div class="name">单价: {{summarySelect.price}}</div>
        </div>
        <div class="item">
          <div class="name">分配股数: {{summarySelect.allotAs}}</div>
        </div>
      </div>
      <base-screen class="custom-screen">
        <a-form-model ref="formRefSelect" layout="inline" :model="queryRefSelect" @submit="handleSearchSelect" @submit.native.prevent>
          <a-row>
            <a-form-model-item label="手机号" prop="loginName">
              <a-input :maxLength="11" v-model="queryRefSelect.loginName" placeholder="手机号"></a-input>
            </a-form-model-item>
            <a-form-model-item label="注册时间" prop="dateRange">
              <a-range-picker v-model='queryRefSelect.dateRange' format="YYYY-MM-DD" value-format="YYYY-MM-DD" />
            </a-form-model-item>
            <a-form-model-item label="用户类型" prop="userType">
              <a-select v-model="queryRefSelect.userType" placeholder='请选择' style="width: 200px">
                <a-select-option v-for='item in userTypes' :key='item.value' :value="item.value">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item class="screen-btns">
              <a-button type="primary" @click="handleSearchSelect">查询</a-button>
              <a-button @click="handleResetSelect">重置</a-button>
            </a-form-model-item>
          </a-row>
        </a-form-model>
      </base-screen>
      <base-table :columns="columns" :dataSource="dataRefSelect" :pagination="pageRtvSelect" :rowKey="record => record.userId" :loading="loadingRefSelect" @change="handleTableChangeSelect"
                  :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
        <template #userType="record">
          <span>{{userTypes.find(x=>x.value === record).name}}</span>
        </template>
        <template slot-scope="text,record,index" slot="saleAs">
          <a-form-model-item label  :class="{'has-error':record.saleAsError}" :help="record.saleAsError">
            <a-input-number :precision="2" :min="0" :max='record.saleBeforeAs' v-model="record.saleAs"
                            @change="changeSaleAs($event,record,index)" placeholder="请输入" style="width:120px;"></a-input-number>
          </a-form-model-item>
        </template>
      </base-table>
    </a-modal>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { userTypes } from '../../utils/constants'
import { shop } from '@/api'
const columns = [
  {
    title: '用户类型',
    dataIndex: 'userType',
    scopedSlots: { customRender: 'userType' },
  },
  {
    title: '手机号',
    dataIndex: 'loginName',
  },
  {
    title: '注册时间',
    dataIndex: 'regisTime',
  },
  {
    title: '金额',
    dataIndex: 'allotWaitMoney',
  },
  {
    title: '单价',
    dataIndex: 'price',
  },
  {
    title: '分配股数',
    dataIndex: 'allotAs',
  },
  {
    title: '添加时间',
    dataIndex: 'createTime',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
]
export default {
  name: 'PageSaleDetail',
  props: {
    logId: String, // money voucher
    state: Number,
  },
  setup (props, { root, emit }) {
    const visibleEdit = ref(false)
    const formEditRef = ref(null)
    const formRef = ref(null)
    const formRefSelect = ref(null)
    const loadingRef = ref(false)
    const loadingRefSelect = ref(false)
    const visibleAdd = ref(false)
    const summary = ref({
      allotWaitMoney: undefined,
      price: undefined,
      allotAs: undefined,
    })
    const summarySelect = ref({
      allotWaitMoney: 0,
      price: 0,
      allotAs: 0,
    })
    const form = ref({
      detailId: undefined,
      loginName: undefined,
      saleBeforeAs: undefined,
      saleAs: undefined,
      saleAfterAs: undefined,
    })
    const queryRef = ref({
      logId: undefined,
      loginName: undefined,
      dateRange: [],
      beginDate: undefined,
      endDate: undefined,
      userType: undefined,
    })
    const queryRefSelect = ref({
      logId: undefined,
      loginName: undefined,
      dateRange: [],
      beginDate: undefined,
      endDate: undefined,
      userType: undefined,
    })
    const pageRtv = ref({
      current: 1,
      size: 10,
      total: 0,
    })
    const pageRtvSelect = ref({
      current: 1,
      size: 100,
      total: 0,
    })
    const selectedRowKeys = ref([])
    const selectedRows = ref([])
    const dataRef = ref([])
    const dataRefSelect = ref([])
    function handleSearch () {
      pageRtv.value.current = 1
      getTableData()
    }
    function handleSearchSelect () {
      pageRtvSelect.value.current = 1
      getTableDataSelect()
    }
    async function getTableData () {
      let beginDate = queryRef.value.dateRange && queryRef.value.dateRange.length ? queryRef.value.dateRange[0] : ''
      let endDate = queryRef.value.dateRange && queryRef.value.dateRange.length ? queryRef.value.dateRange[1] : ''
      queryRef.value.beginDate = beginDate
      queryRef.value.endDate = endDate
      let query = {
        logId: props.logId,
        loginName: queryRef.value.loginName,
        userType: queryRef.value.userType,
        beginDate: beginDate,
        endDate: endDate,
      }
      loadingRef.value = true
      dataRef.value = []
      const { data, page } = await shop.getSaleBuyDetailPage({ ...query, ...pageRtv.value })
      loadingRef.value = false

      dataRef.value = data || []
      pageRtv.value.total = page.total
    }
    getTableData()

    async function getSummaryData () {
      let query = {
        logId: props.logId,
      }
      const { data } = await shop.getSummaryDataSaleBuy(query)
      summary.value = data || []
    }
    getSummaryData()
    async function getTableDataSelect () {
      selectedRowKeys.value = []
      let beginDate = queryRefSelect.value.dateRange && queryRefSelect.value.dateRange.length ? queryRefSelect.value.dateRange[0] : ''
      let endDate = queryRefSelect.value.dateRange && queryRefSelect.value.dateRange.length ? queryRefSelect.value.dateRange[1] : ''
      queryRefSelect.value.beginDate = beginDate
      queryRefSelect.value.endDate = endDate
      let query = {
        logId: props.logId,
        loginName: queryRefSelect.value.loginName,
        userType: queryRefSelect.value.userType,
        beginDate: beginDate,
        endDate: endDate,
      }
      loadingRefSelect.value = true
      dataRefSelect.value = []
      const { data, page } = await shop.getSaleBuySelectUserPage({ ...query, ...pageRtvSelect.value })
      loadingRefSelect.value = false

      dataRefSelect.value = data || []
      pageRtvSelect.value.total = page.total
    }
    function handleTableChange ({ current }) {
      pageRtv.value.current = current
      getTableData()
    }
    function handleTableChangeSelect ({ current }) {
      pageRtvSelect.value.current = current
      getTableDataSelect()
    }
    async function handleReset () {
      formRef.value.resetFields()
      await root.$nextTick()
      handleSearch()
    }
    async function handleResetSelect () {
      formRefSelect.value.resetFields()
      await root.$nextTick()
      handleSearchSelect()
    }
    async function selectUser () {
      visibleAdd.value = true
      getTableDataSelect()
    }
    async function handleSubmit () {
      if (selectedRows.value.length > 0) {
        root.$confirm({
          title: '操作提示',
          content: '确认添加所选分配明细吗？',
          onOk: async () => {
            submit()
          }
        })
      } else {
        root.$message.error('请选择要添加的用户')
      }
    }
    async function submit () {
      const { msg, code } = await shop.saveSaleBuyDetail({
        logId: props.logId,
        shopSaleBuyDetailList: selectedRows.value,
      })
      if (code === '00000') {
        root.$message.success('提交成功')
        handelCancel()
        getTableData()
        getSummaryData()
      } else {
        root.$message.error(msg || '提交失败')
      }
    }
    async function handelCancel () {
      formRef.value.resetFields()
      visibleAdd.value = false
      selectedRowKeys.value = []
      selectedRows.value = []
    }
    function onSelectChange (keys, rows) {
      selectedRowKeys.value = keys
      selectedRows.value = rows
      // console.log(selectedRows.value)
      if (selectedRows.value.length > 0) {
        let i = 0
        let saleBeforeAs = 0
        let saleAs = 0
        let saleAfterAs = 0
        for (let row of selectedRows.value) {
          saleBeforeAs += Number(row.allotWaitMoney)
          saleAs = Number(row.price)
          saleAfterAs += Number(row.allotAs)
          if (i === selectedRows.value.length - 1) {
            summarySelect.value = {
              allotWaitMoney: saleBeforeAs.toFixed(2),
              price: saleAs.toFixed(2),
              allotAs: saleAfterAs.toFixed(2),
            }
          }
          i = i + 1
        }
      } else {
        summarySelect.value = {
          allotWaitMoney: 0,
          price: 1,
          allotAs: 0,
        }
      }
    }
    function changeSaleBuyAs (e, record, index) {
      let regExp = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
      if (!regExp.test(record.saleAs)) {
        record.saleAsError = '必须大于0'
        dataRefSelect.value[index].saleAsError = ''
        return
      }
      record.saleAsError = ''
      record.saleAfterAs = (record.saleBeforeAs - record.saleAs).toFixed(2)
      if (selectedRows.value.length > 0) {
        let i = 0
        let saleBeforeAs = 0
        let saleAs = 0
        let saleAfterAs = 0
        for (let row of selectedRows.value) {
          saleBeforeAs += Number(row.saleBeforeAs)
          saleAs += Number(row.saleAs)
          saleAfterAs += Number(row.saleAfterAs)
          if (i === selectedRows.value.length - 1) {
            summarySelect.value = {
              saleBeforeAs: saleBeforeAs.toFixed(2),
              saleAs: saleAs.toFixed(2),
              saleAfterAs: saleAfterAs.toFixed(2),
            }
          }
          i = i + 1
        }
      }
      // console.log(selectedRows.value)
    }
    function changeSaleBuyAsEdit () {
      form.value.saleAfterAs = (form.value.saleBeforeAs - form.value.saleAs).toFixed(2)
    }
    async function edit (record) {
      form.value = record
      visibleEdit.value = true
    }
    async function handleSubmitEdit () {
      formEditRef.value.validate((v) => {
        if (v) {
          root.$confirm({
            title: '操作提示',
            content: '确认提交修改吗？',
            onOk: async () => {
              const { msg, code } = await shop.editSaleBuyDetail(form.value)
              if (code === '00000') {
                root.$message.success('提交成功')
                handelCancelEdit()
                getTableData()
                getSummaryData()
              } else {
                root.$message.error(msg || '提交失败')
              }
            }
          })
        }
      })
    }
    async function handelCancelEdit () {
      formEditRef.value.resetFields()
      visibleEdit.value = false
    }
    async function deleteDetail (record) {
      root.$confirm({
        title: '操作提示',
        content: '确认刪除吗？',
        onOk: async () => {
          const { code, msg } = await shop.deleteSaleBuyDetail({
            detailId: record.detailId
          })
          if (code === '00000') {
            root.$message.success('操作成功')
            getTableData()
            getSummaryData()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }
    return {
      userTypes,
      loadingRef,
      loadingRefSelect,
      queryRef,
      queryRefSelect,
      handleSearch,
      handleReset,
      handleTableChange,
      pageRtv,
      pageRtvSelect,
      handleSearchSelect,
      handleResetSelect,
      handleTableChangeSelect,
      formRef,
      formRefSelect,
      dataRef,
      dataRefSelect,
      columns,
      visibleAdd,
      handleSubmit,
      handelCancel,
      selectUser,
      onSelectChange,
      selectedRowKeys,
      selectedRows,
      changeSaleBuyAs,
      changeSaleBuyAsEdit,
      edit,
      deleteDetail,
      visibleEdit,
      formEditRef,
      form,
      handleSubmitEdit,
      handelCancelEdit,
      summary,
      summarySelect,
    }
  }
}
</script>

<style lang="less" scoped>
.summary {
  display: flex;
  padding-bottom: 20px;
}
.summary .item {
  min-width: 200px;
  & .name {
      font-size: 14px;
      color: #666;
    }
  & .value {
      font-size: 20px;
      padding-top: 10px;
    }
  &.expand {
     flex: 1 0 auto;
   }
}
</style>
